<template>
  <div class="flex-1 mt-1 bg-gray-100 px-4 pt-8 shadow-lg items-center">
    <div class="bg-white w-fullrounded-lg shadow">
      <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-5">
        <div class="flex">
           <button
            :class="$colors.primary"
            class="rounded-full flex justify-center items-center w-8 h-8 mr-3"
            @click="RouterBack()"
          >
            <i class="material-icons">chevron_left</i>
          </button>
          <div v-if="formData._id" class="text-xl font-semibold text-green-600">
           {{$t('update_order')}}
          </div>
          <div v-else class="text-xl font-semibold text-green-500 ">
            {{$t('new_order')}}
          </div>
        </div>
      </div>
      <!-- ********************************************************Customer information **********************************-->

      <div class="px-6 mt-3">
        <div class="flex w-full space-x-2">
          <div
            class="flex w-1/2 justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg"
          >
            <div class="flex w-full">
              <span class="rounded-full h-12 w-12 bg-gray-200">
                <i class="material-icons p-3 text-green-400 ">person</i>
              </span>

              <div class="ml-5 w-full">
                <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
                  {{$t('customer_information')}}
                </div>
                <div class="text-sm font-light text-gray-500"></div>
                <div class="w-full space-y-4">
                  <div :class="!formData._id && !formData.customer.country?'animation-pulse':''"
              class="border hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="country" class="bg-white text-gray-700 px-1"
                    >{{$t('country')}}</label
                  >
                </p>
              </div>
              <p>
                <v-select
                  @input="setSelected"
                  v-model="formData.customer.country"
                  :options="warehousesusers"
                  label="name"
                >
                  <template slot="option" slot-scope="option">
                    <img
                      class="flag-img"
                      :src="$f.getCountryCode(option.code)"
                    />
                    {{ option.name }}
                  </template>
                </v-select>
              </p>
            </div>
                  <div class="flex">
                    
                    <div class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                      <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                        <p>
                          <label
                            for="customerFullName"
                            class="bg-white text-gray-700 px-1"
                            >{{$t('full_name')}}
                            <span :class="$colors.required">*</span></label
                          >
                        </p>
                      </div>
                      <p>
                        <input
                          id="customerFullName"
                          autocomplete="false"
                          tabindex="0"
                          type="text"
                          v-model="formData.customer.fullName"
                          class="py-2 text-xs px-2 outline-none block h-full w-full"
                        />
                      </p>
                    </div>
                  </div>

                  <div
                    class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
                  >
                    <div
                      class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                    >
                      <p>
                        <label
                          for="customerPhone"
                          class="bg-white text-gray-700 px-1"
                          >{{$t('phone')}} <span :class="$colors.required">*</span></label
                        >
                      </p>
                    </div>
                    <p>
                      <input
                        id="customerPhone"
                        autocomplete="false"
                        tabindex="0"
                        type="text"
                        v-model="formData.customer.phone"
                        class="py-2 text-xs px-2 outline-none block h-full w-full"
                      />
                    </p>
                  </div>
         
                   <div
                    class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
                  >
                    <div
                      class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                    >
                      <p>
                        <label
                          for="city"
                          class="bg-white text-gray-700 px-1"
                          >{{$t('city')}} <span :class="$colors.required">*</span></label
                        >
                      </p>
                    </div>
                    <p>
                      <v-select  @input="setSelectedCity" v-model="formData.customer.city" :options="cities" ></v-select>
                    </p>
                  </div>
                    <div v-if="formData.customer.city && feesShipp && selectContry == 'MA'" class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                      <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                        <p>
                          <label
                            for="Frais"
                             
                            class="bg-white text-gray-700 px-1"
                            >{{$t('shipping_cost')}} <sup>({{currentCurrency}})</sup>
                           </label>
                        </p>
                      </div>
                      <p>
                        <input
                          id="Frais"
                          autocomplete="false"
                          disabled
                          tabindex="0"
                          :value="feesShipp"
                          type="text"
                          class="py-2 text-xs px-2 outline-none block h-full w-full"
                        />
                      </p>
                    </div>
                  <div
                    class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
                  >
                    <div
                      class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                    >
                      <p>
                        <label
                          for="customerAddress"
                          class="bg-white text-gray-700 px-1"
                          >{{$t('adress')}}
                         </label
                        >
                      </p>
                    </div>
                    <p>
                      <textarea
                        id="customerAddress"
                        autocomplete="false"
                        tabindex="0"
                        type="text"
                        v-model="formData.customer.address"
                        class="py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full"
                      ></textarea>
                    </p>
                  </div>

                  <!-- <div
                    v-if="currentUser.type == 'Admin' || currentUser.type == 'CEO'"
                    class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
                  >
                    <div
                      class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                    >
                      <p>
                        <label
                          for="customerShippingAddress"
                          class="bg-white text-gray-700 px-1"
                          >Shipping address
                          <span :class="$colors.required">*</span></label
                        >
                      </p>
                    </div>
                    <p>
                      <textarea
                        id="customerShippingAddress"
                        autocomplete="false"
                        tabindex="0"
                        type="text"
                        v-model="formData.customer.shippingAddress"
                        class="py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full"
                      ></textarea>
                    </p>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <!-- ****************************** Order Information *********************************************-->

          <div
            class="flex w-1/2 justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg"
          >
            <div class="flex w-full mt-2">
              <span class="rounded-full h-12 w-12 bg-gray-200">
                <i class="material-icons p-3 text-green-400 "
                  >local_grocery_store</i
                >
              </span>

              <div class="ml-5 w-full">
                <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
                  {{$t('order_information')}}
                </div>
                <div class="text-sm font-light text-gray-500"></div>
                <div class="w-full space-y-4">
                  <div class="flex">
                    <div
                      class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
                    >
                      <div
                        class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                      >
                        <p>
                          <label
                            for="source"
                            class="bg-white text-gray-700 px-1"
                            >{{$t('order_source')}}
                            </label
                          >
                        </p>
                      </div>
                      <p>
                        <input
                          id="source"
                          autocomplete="false"
                          tabindex="0"
                          type="text"
                          v-model="formData.source"
                          class="py-2 text-xs px-2 outline-none block h-full w-full"
                        />
                      </p>
                    </div>
                  </div>

                  <div
                    class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
                  >
                    <div
                      class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                    >
                      <p>
                        <label
                          for="sourceLink"
                          class="bg-white text-gray-700 px-1"
                          >{{$t('order_source_link')}}
                          </label
                        >
                      </p>
                    </div>
                    <p>
                      <input
                        id="sourceLink"
                        autocomplete="false"
                        tabindex="0"
                        type="text"
                        v-model="formData.sourceLink"
                        class="py-2 text-xs px-2 outline-none block h-full w-full"
                      />
                    </p>
                  </div>

                  <!-- <div
                    class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
                  >
                    <div
                      class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                    >
                      <p>
                        <label for="date" class="bg-white text-gray-700 px-1"
                          >{{$t('order_date')}}
                          <span :class="$colors.required">*</span></label
                        >
                      </p>
                    </div>
                    <p>
                      <datetime-v2  v-model="formData.date" placeholder="Order date ..." input-class="py-2 text-xs px-2 outline-none block h-full w-full" ></datetime-v2> 
                     
                    </p>
                  </div> -->
                  <div v-if="currentUser.type != 'Seller'"
                    class="border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
                  >
                    <div
                      class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                    >
                      <p>
                        <label for="date" class="bg-white text-gray-700 px-1"
                          >Shipping date
                          <span :class="$colors.required">*</span></label
                        >
                      </p>
                    </div>
                    <p>
                       <datetime-v2  :min-datetime="$moment(Date.now()).format('yyyy-MM-DD')"  v-model="formData.shippingdate" placeholder="Shipping date ..." input-class="py-2 text-xs px-2 outline-none block h-full w-full" ></datetime-v2> 
                    </p>
                  </div>
                <!--  <div
                    v-if="currentUser.type == 'Admin' || currentUser.type == 'CEO'"
                    class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
                  >
                    <div
                      class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                    >
                      <p>
                        <label for="date" class="bg-white text-gray-700 px-1"
                          >Price Total
                          <span :class="$colors.required">*</span></label
                        >
                      </p>
                    </div>
                    <p>
                      <input
                        autocomplete="false"
                        tabindex="0"
                        type="text"
                        v-model="formData.totalPrice"
                        class="py-2 text-xs px-2 outline-none block h-full w-full"
                      />
                    </p>
                  </div> -->
                  <!-- <div
                    v-if="currentUser.type == 'Admin' || currentUser.type == 'CEO'"
                    class="border h-10 hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
                  >
                    <div
                      class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                    >
                      <p>
                        <label for="seller" class="bg-white text-gray-700 px-1"
                          >Seller
                          <span :class="$colors.required">*</span></label
                        >
                      </p>
                    </div>
                    <p>
                      <select
                        id="type"
                        name="seller"
                        autocomplete="type"
                        v-model="formData.seller"
                        @change="changeSeller"
                        class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white"
                      >
                        <option selected disabled :value="null">Seller</option>
                        <option
                          v-for="seller in sellers"
                          :key="seller._id"
                          :value="seller"
                        >
                          {{ seller.email }}
                        </option>
                      </select>
                    </p>
                  </div> -->
                   <div :class="!formData._id && !formData.seller?'animation-pulse':''" v-if="(currentUser && currentUser.type && currentUser.type != 'Seller') && formData.customer.country" class="border seller-select h-10 hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                      <p>
                        <label for="seller" class="bg-white text-gray-700 px-1"> {{$t('seller')}}
                        <span :class="$colors.required">*</span></label>
                      </p>
                    </div>    
                    <p>
                        <v-select label="fullName" :disabled="formData && formData._id?true:false"  @search="searchSellers($event,'hh')"  @input="changeSeller" v-model="formData.seller" :options="sellers"></v-select>
                    </p>         
                 </div>  
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ********************************************************Product Table assigned to order **********************************-->

        <div v-if="formData.customer.country && formData.seller" class="flex w-full">
          <div class="flex space-x-2 w-full shadow-xs mt-1 rounded-lg">
            <div class="flex w-full justify-between items-center h-auto p-6">
              <div class="flex w-full">
                <span class="rounded-full h-12 w-12 bg-gray-200">
                  <i class="material-icons p-3 text-green-500 ">local_mall</i>
                </span>
                <div class="ml-3 w-full p-2 pb-8">
                  <div
                    class="w-full flex text-base font-semibold text-gray-600"
                  >
                    <p class="w-1/2">{{$t('products')}}</p>
                    <div class="w-full">
                      <button
                        :class="!formData._id && formData.details.length==0?'animation-pulse':''"
                        @click="OpenPopUp"
                        class="flex text-white mx-auto max-w-sm rounded-md text-center bg-green-600  py-2 px-8 items-center focus:outline-none md:float-right"
                        :disabled="isDisabled || (formData.status && formData.status.name && formData.status.name === 'Confirmed'?true:false)"
                      >
                       {{$t('select_product')}}
                      </button>
                    </div>
                  </div>

                  <div class="mt-4 overflow-x-auto">
                    <table
                      v-if="formData.details"
                      class="min-w-full leading-normal"
                    >
                      <thead>
                        <tr>
                          <th
                            class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
                          >
                            {{$t('name')}}
                          </th>

                         <!-- <th
                            class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
                          >
                            {{$t('price')}}
                          </th>-->
                           <th
                            class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
                          >
                            {{$t('unit_price')}}
                          </th>
                          <th
                            class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
                          >
                            {{$t('quantity')}}
                          </th>
                          

                          <th
                            class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
                          >
                           {{$t('total')}}
                          </th>

                          <th
                            class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
                          >
                            {{$t('actions')}}
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(item, index) in formData.details"
                        :key="index"
                      >
                        <tr>
                          <td
                            class="px-6 py-2 whitespace-no-wrap border-b border-gray-200"
                          >
                            <div
                              class="text-xs leading-5 capitalize px-2 rounded-full text-gray-900"
                            >
                              {{ item.product.name }}
                            </div>
                          </td>

                         <!-- <td
                            class="px-6 py-2 whitespace-no-wrap border-b border-gray-200"
                          >
                            <div
                              class="text-xs leading-5 capitalize px-2 rounded-full text-gray-900"
                            >
                              {{ item.product.price }} <sup class="text-black">{{$f.getcurrencyCodeByCountry(options,selectContry) }}</sup>
                            </div>
                          </td>-->
                       <td class="px-6 py-2 whitespace-no-wrap border-b border-gray-200">
                            <div>
                              <input autocomplete="false" :disabled="formData.status && formData.status.name && formData.status.name === 'Confirmed'?true:false" tabindex="0" min="0" type="number" v-model="item.unitPrice" class="border border-green-600  py-2 text-xs px-2 outline-none block h-full w-full"
                      />
                            </div>
                          </td>
                          <td
                            class="px-6 py-2 whitespace-no-wrap border-b border-gray-200"
                          >
                            <div class="flex space-x-6">
                              <button
                                :class="$colors.textPrimary"
                                class="bg-green-600  rounded-md w-6 h-6 flex items-center focus:outline-none"
                                @click="addQuantity(index)"
                                :disabled="formData.status && formData.status.name && formData.status.name === 'Confirmed'?true:false"
                              >
                                <span class="font-bold text-2xl text-white">
                                  <i class="material-icons mt-2">add</i>
                                </span>
                              </button>
                              <span
                                :class="$colors.textPrimary"
                                class="text-gray-700 text-sm h-6 flex items-center focus:outline-none"
                              >
                                <input
                                  class="focus:outline-none w-12 text-center"
                                  type="text"
                                  v-model="item.quantity"
                                />
                              </span>

                              <button
                                :class="$colors.textPrimary"
                                @click="removeQuantity(index)"
                                :disabled="formData.status && formData.status.name && formData.status.name === 'Confirmed'?true:false"
                                class="bg-green-600  rounded-md w-6 h-6 text-white flex items-center focus:outline-none"
                              >
                                <span
                                  class="font-bold text-2xl text-white mt-1"
                                >
                                  <i class="material-icons">remove</i>
                                </span>
                              </button>
                            </div>
                            <div
                              class="text-xs leading-5 capitalize px-2 rounded-full text-gray-900"
                            ></div>
                          </td>
                          
                          <td
                            class="px-6 py-2 whitespace-no-wrap border-b border-gray-200"
                          >
                            <div
                              class="text-xs leading-5 capitalize px-2 rounded-full text-gray-900"
                            >
                              {{ item.unitPrice * item.quantity }} <sup class="text-black">({{currentCurrency}})</sup>
                            </div>
                          </td>

                          <td
                            class="py-4 whitespace-no-wrap border-b border-gray-200"
                          >
                            <div class="flex justify-center">
                              <button
                                @click="removeProduct(item, index)"
                                :disabled="formData.status && formData.status.name && formData.status.name === 'Confirmed'?true:false"
                                :class="$colors.actionTable"
                                class="rounded-full flex focus:outline-none w-8 h-8 mr-3 mt-1"
                              >
                                <i class="material-icons mx-1">delete</i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                         <tr  >
                          <td colspan="4"
                            class="px-6 text-right py-2 whitespace-no-wrap border-gray-200"
                          >
                            <div
                              class="text-xs leading-5 capitalize px-2 rounded-full font-bold text-gray-900"
                            >
                            {{$t('total_price')}}: {{TotalPrice()}} <sup class="text-black">({{currentCurrency}})</sup>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ************************************************************************************************-->
      </div>

      <div class="mt-6 pt-3 flex justify-center p-10">
         <button  @click="save()" class="rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600  hover:shadow-inner hover:bg-green-700  transition-all duration-300 loidingconfirm relative" :class="loading?'noselect':''">
          <loading :active.sync="loading" :is-full-page="fullPage"></loading>
   
          {{$t('save')}}
        </button>
      </div>
      <!-- ***********************************Pop up Affectation products to Order************************************************ -->

        <vue-modal-2  name="myModal" :headerOptions="{title: $t('add_products')}" :noFooter="true" modalSize="lg" @on-close="closePopUp('myModal')">
        <div class="flex flex-col w-full h-auto p-5 pt-0 mb-12">
          <!-- Header -->
          <div class="flex w-full h-auto justify-center items-center">
            <div
              class="flex mb-2 w-full h-auto py-2 justify-center items-center text-2xl font-bold"
            >
              <span
                class="w-screen md:w-full h-10 cursor-pointer border border-gray-200 rounded text-sm flex"
              >
                <input
                  type="search"
                  name="search"
                  v-model="searchText"
                  placeholder="Search"
                  class="flex-grow px-4 rounded-l-full rounded-r-full text-sm focus:outline-none"
                />
                <i class="material-icons text-blue-400 m-2 mr-5 text-lg w-4 h-4"
                  >search</i
                >
              </span>
            </div>
          </div>
          <div>
            <div v-if="products.length <= 0" class="py-2">
              <span
                class="w-full flex justify-center text-center py-20 border-b border-gray-200 text-base"
              >
                <p class="font-semibold flex text-blue-500 whitespace-no-wrap">
                  <span class=""> {{$t('no_products_found')}}</span>
                </p>
              </span>
            </div>
            <div
              v-for="(item, index) in resultSearchUsers"
              :key="index"
              :value="item._id"
              class="flex w-full space-x-12 h-auto py-1 px-8 rounded border border-gray-300 mb-2" :style="item.type && item.type === 'affiliate'?'background: linear-gradient(90deg, #ff5b2d, rgb(25 166 157 / 4%));':''">
              <div>
                <input
                  type="checkbox"
                  :class="'product-'+index"
                  class="form-checkbox mt-6 h-5 w-5 text-blue-500 border-blue-500"
                  @change="addProductsToTemp(item,index)"
                  v-model="item.check"
                />
              </div>

              <div>
                <img
                  :alt="item.name"
                  :src="item.picture||'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'"
                  class="h-16 w-16 rounded-md mx-auto"
                />
              </div>
              <div class="col-span-2 sm:col-span-4 xl:col-span-4">
                <h3 class="text-gray-80 mt-1">{{ item.name }}</h3>
                <p v-if="item.type === 'normal'" class="text-gray-600 text-xs">{{ exceptDes(item.description) }}</p>
                <p v-if="item.type === 'affiliate'" class="text-gray-700 text-xs uppercase">Offre affiliate</p>
              </div>

             <!-- <div class="mt-4">
                <div
                  class="justify-center text-xs text-center pl-3 pr-2 w-auto bg-blue-100 font-semibold rounded-full text-blue-500"
                >
                  <p>{{ item.price }} <sup class="text-black">{{$f.getcurrencyCodeByCountry(options,selectContry) }}</sup></p>
                </div>
              </div>-->
              
            </div>
          </div>
        </div>

        <div class="modal-footer px-5 border0-t text-right">
          <button
            @click="closePopUp('myModal')"
            class="flex text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right"
          >
            
            {{$t('cancel')}}
          </button>
          <button
            @click="ConfirmOperation"
            class="flex mr-2 text-white mx-auto max-w-sm rounded text-center bg-blue-500 py-2 px-8 items-center focus:outline-none md:float-right"
          >
            {{$t('confirm')}}
          </button>
        </div>
      </vue-modal-2>
      <!-- ******************************************************** -->

      <vue-modal-2 name="warehouseParent" :headerOptions="{title: 'Choisir la ville de stockage'}" :noFooter="true" modalSize="md">
            <div class="flex flex-col w-full h-auto">
            <!-- Header -->
            <!-- Modal Content-->
            <div class="bg-white rounded px-2  pb-2 flex flex-col">
               <loading :active.sync="loading" :is-full-page="fullPage"></loading>
                <p class="font-bold text-center">S'il vous plait Choisir la ville de stockage de produit</p>
                <div  class="shadow bg-white my-2 p-1 border-2 border-gray-500 rounded svelte-1l8159u">
                      <v-select  v-model="formData.zone" :options="checkWraehouseParent" label="name"></v-select>
                </div>
                <div class="flex p-2 mt-4">
                      <div class="flex-auto flex flex-row-reverse">
                          <button :disabled="loading" @click="save('warehouseParent')" class="w-1/3 p-3 btn-action rounded bg-blue-600 hover:bg-blue-500 text-white font-semibold focus:outline-none"> {{$t('confirm')}}</button>
                          <button :disabled="loading"  @click="closePopUp('warehouseParent')" class="w-1/3 p-3 mr-4 rounded bg-gray-600 hover:bg-gray-500 text-white font-semibold focus:outline-none">Annuler</button>
                      </div>
                  </div>
            </div>
            <!-- End of Modal Content-->
          </div>
         </vue-modal-2>

    </div>
  </div>
</template>



<script>
export default {
  data() {
    return {
      formData: {
        source: "",
        affiliate:{},
        sourceLink: "",
        totalPrice:0,
        shippingdate:this.$momentTimezone.tz(this.$moment(),'UTC').format(),
        customer: {
          fullName: "",
          phone: "",
          city: "",
          //  shippingAddress: "",
        },
        details: [],
        status: "",
        seller: null,
      },
      currentUser: {
        role: {},
      },
      searchText: "",
      quantity: 0,
      status: [],

      products: [],
      show_modal: true,
      limit: 10,
      warhouseId:null,
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 1,
        per_page: 0,
      },
      productselected: [],
      numberItemRepeated: 0,
      sellers: [],
      isDisabled: true,
      sellerExists:false,
      options: this.$countries,
      selectContry:"",
      warehouses:[],
      warehousesusers:[],
      currentCurrency:"",
      cities:[],
      feesShipp:null,
      loading:false,
      fullPage: false,
      checkWraehouseParent:[],
      sous_warehouses:[]
    };
  },
  async mounted() {
    await this.getUser();
    if(this.currentUser.type == "Seller") {
        this.formData.seller = this.currentUser;
        if (this.formData.seller) this.isDisabled = false;
    }

    await this.getStatus();
    await this.dataToEdit();
    this.dateMin();
  },
  methods: {
      RouterBack: function() {
                this.$router.back();
            },
     async getCurrency(){
      await this.$store.dispatch('wharhouse/getCurrency').then((res) => {
            this.currency=res;
        })
    },
    searchSellers: _.debounce(async function(search) {
      if(this.selectContry){
        const filter = {
          type: "Seller",
          limit:10,
          countries: this.selectContry,
          name: search,
        };
        const res= await this.$server.find("users",filter);

      if (res.content.results) this.sellers = res.content.results;
      else this.sellers = [];
      }
     
    }, 500),
    getElementInArray(data,value){
        var result  = data.filter(function(o){
            let whar=o.warehouse;
            if(o.warehouse._id) whar=o.warehouse._id;
            return whar == value.toString();
        } );
        return result? result[0] : null; // or undefined
    },
    async getWarehouses(){
        const filter = {limit:10};
        const res = await this.$server.search("warehouses",filter);
        if (res.content.results) {
            let data = res.content.results;
            for(let i in data){
              this.warehouses.push({name:this.$f.getCountryNameByCode(this.options,data[i].country),code:data[i].country})
            }
        } else this.warehouses = [];
    }, 
    async getCities(country){
      const resCities = await this.$server.get("zones", { allCities: true,country:country}); 
            if(resCities.content.length>0)  this.cities= resCities.content;
            else this.cities=[];
    },
    async getCurrencyBycontry(country){
          const resW = await this.$server.get("warehouses", { country: country}); 
          this.currentCurrency=resW.content.currency;
          this.warhouseId=resW.content;
    },
    async setSelected(contry) {
       if(contry){
         this.formData.details = [];this.sous_warehouses=[];
         this.formData.customer.country=contry.name;
         this.selectContry=contry.code;
         if (this.currentUser && this.currentUser.type && this.currentUser.type != "Seller") await this.getSellers(contry.code);
         await this.getCities(contry.code);
         await this.getCurrencyBycontry(contry.code);
         if(['NRP',"CEO",'Return','CancelledOrder','TeleConsultant','Admin','ToRemind'].indexOf(this.currentUser.type) != -1){
            const resW = await this.$server.search("warehouses", { country: contry.code,all:true,limit:10}); 
            if(resW.content && resW.content.results) this.sous_warehouses=resW.content.results.filter(item => !item.is_primary);
         }
       }
    },
    async getFeesShippBycity(city){
        const res = await this.$server.search("zones",{cities:city});
        if(res.content.results) this.feesShipp=res.content.results[0].price;
    },
    async setSelectedCity(city){
      if(city){
         await this.getFeesShippBycity(city);
       } else this.feesShipp=null
    },
    dateMin(){ 
      // document.getElementById('date').setAttribute('min', this.$moment(Date.now()).format('yyyy-MM-DD')); 
    },
    exceptDes(text){
         if (text && text.length > 60) text = text.substring(0, 60) + "...";
      return text;
    },
    // changeSeller(seller) {
    //   console.log('seller_____________',seller  )
    //   if (this.formData.seller) {
    //     this.isDisabled = false;
    //     if (this.formData.details) {
    //       if (this.formData.details[0]) {
    //         if (
    //           this.formData.details[0].product.seller !=
    //           this.formData.seller._id
    //         ) {
    //           this.formData.details = [];
    //         }
    //       }
    //     }
    //   } else {
    //     this.isDisabled = true;
    //   }
    // },
    TotalPrice(){
      let Total=0;
        for (const element of this.formData.details) {
            Total+=element.unitPrice * element.quantity;
        }
        this.formData.totalPrice=Total;
        return Total;
     },
    async getUser() {
      const res = await this.$server.me("users");
      //console.log(res);
      if (res.content) {
          this.currentUser = res.content;
          await this.getWarehouses();
          for (let i in this.warehouses) {
            for (let j in this.currentUser.countries) {
                if(this.warehouses[i].code===this.currentUser.countries[j]) this.warehousesusers.push(this.warehouses[i]);
            }
          }
      }else {this.currentUser = {};this.warehousesusers=[]}
      //console.log(' this.warehousesusers-----', this.warehousesusers)
    },

    async getProducts() {
        if (this.formData.seller) {
           const filters = {limit:200,seller: this.formData.seller._id||this.formData.seller,'details.warehouse':this.warhouseId._id,};
          // console.log('filter pr-----',filters)
            const res = await this.$server.search("products", filters);
            if (res.content.results) {
                this.products = res.content.results;
                this.paginate.total = res.content.total;
                this.paginate.lastpage = res.content.last_page;
                this.paginate.per_page = res.content.per_page;
                this.paginate.currentpage = res.content.current_page;
            //  console.log('this.products-----',this.products)
                for (let i in this.products) {
                  this.products[i].seller = this.products[i].seller;
                  const resProductW= this.getElementInArray(this.products[i].details,this.warhouseId._id);
                  this.products[i].price = resProductW.price;
                }
            } else this.products = [];
      } else {
        alert(this.$t('must_select_seller'), "warning");
      }
    },

    async getStatus() {
      const res = await this.$server.search("status");
      if (res.content.results) this.status = res.content.results;
      else this.status = [];
    },
      SumTotalPrice(data) {
       let total=0;
        for (let i in data) {
          total += data[i].quantity * data[i].unitPrice;
        }
        return total;
     },
    async save(checkParent=null) {
      if (this.formData._id) {
         this.loading=true;
         this.formData.totalPrice=this.SumTotalPrice(this.formData.details);
         this.formData.customer.phone= this.formData.customer.phone.replace(/[^\d]/g, ''); 
         this.formData.customer.country=this.selectContry;
          if(this.formData.customer.country==='MA') {
            let strFirstThree =  this.formData.customer.phone.substring(0,4);
            if(strFirstThree.indexOf('212') === -1) this.formData.customer.phone='+212'+this.formData.customer.phone;
          }
        const data = await this.$server.update("orders", this.formData);
        console.log('data-------------',data)
         this.loading=false;
        if (data && data._id) {
          // const shippingdata = await this.$server.get("shippings", {order: data._id,});
          //  if(shippingdata.content && shippingdata.content._id){
          //    shippingdata.content.orderObjet=this.formData;
          //    await this.$server.update("shippings", shippingdata.content);
          //  }
          this.formData.customer.country=this.$f.getCountryNameByCode(this.options,this.selectContry);
          alert(this.$t('order_updated'), "success");
          await this.dataToEdit();
        }else {alert(data, "warning");await this.dataToEdit();}
      } else {
        if (this.checkData()) {
          this.loading=true;
          if(['NRP',"CEO",'Return','CancelledOrder','TeleConsultant','Admin','ToRemind'].indexOf(this.currentUser.type) != -1){
         
         if(!checkParent){
           this.checkWraehouseParent=[];
           //check stock if exist
           console.log('sous_warehouses----',this.sous_warehouses)
           if(this.sous_warehouses.length>0){

               for (let index = 0; index < this.formData.details.length; index++) {
                   const orderLine = this.formData.details[index];
                  //  orderLine.warehouseParent=this.warhouseId._id;
                  //  orderLine.warehouseParentName=this.warhouseId.name;
                   const {content:product} = await this.$server.get("products",{  _id: orderLine.product._id||orderLine.product } )
                   //console.log('product -------',product)
                   let dataWhrahouse=this.$f.getElementInArrayByCountry(product.details,this.warhouseId.country,orderLine.quantity,'manuelCreate');
                   if(dataWhrahouse && dataWhrahouse.length>0){
                     this.checkWraehouseParent=dataWhrahouse.map(i => i.warehouse);
                     if(this.checkWraehouseParent.length == 1){
                         orderLine.warehouseParent=this.checkWraehouseParent[0]._id;
                         orderLine.warehouseParentName=this.checkWraehouseParent[0].name;
                     }
                   }else this.checkWraehouseParent=[] 
               }
           }
           console.log('checkWraehouseParent----',this.checkWraehouseParent)
           if(this.checkWraehouseParent.length>1){
               this.loading=false;
               console.log('oook')
               this.$vm2.open('warehouseParent');
               return false
           }

         }

         if(checkParent && !this.formData.zone){
             this.loading=false;
             alert('Ville de stockage required', "warning");
             return false
         }

         if(checkParent && this.formData.zone){
           for (let index = 0; index < this.formData.details.length; index++) {
                 const orderLine = this.formData.details[index];
                 orderLine.warehouseParent=this.formData.zone._id;
                 orderLine.warehouseParentName=this.formData.zone.name;
           }
         }

       }

        //check affiliate order
        if(this.formData.details.find(el=> el.product.type && el.product.type === 'affiliate')){
              if(this.formData.details.length>1) {this.loading = false;return this.$toast.warning("Order type affilitae contient seulment un produit type Offre");}
              this.formData.affiliate.type="affiliate";
          }else this.formData.affiliate={};

      
          this.formData.customer.country=this.selectContry;
          this.formData.totalPrice=this.SumTotalPrice(this.formData.details);
          this.formData.customer.phone= this.formData.customer.phone.replace(/[^\d]/g, ''); 

          let dataWhrahouseSetting=null;
          if(this.formData.seller.settings.length>0) dataWhrahouseSetting= this.getElementInArray(this.formData.seller.settings,this.warhouseId._id);
          if(!dataWhrahouseSetting || (dataWhrahouseSetting && !dataWhrahouseSetting.confirmation && !dataWhrahouseSetting.fulfillement && !dataWhrahouseSetting.shipping)){
            alert("vous n'avait pas activé aucun services veuillez contacter administration rmExpress", "warning");
             this.loading=false;
            return false;
          }
         
         

          const data = await this.$server.create("orders", this.formData);
          this.loading=false;
          if(data && data._id) {
            this.$vm2.close('warehouseParent');
            this.selectContry= "";
            this.formData.customer.country="";
            this.formData = {
              customer: {},
              details: [],
              affiliate:{},
            };
             if (this.currentUser.type == "Seller"){
              this.formData.seller = this.currentUser;
              if (this.formData.seller) this.isDisabled = false;
            }
            alert(this.$t('order_created'), "success");
          }else  alert(data, "warning");
        } else {
          alert(this.$t('all_required_field'), "warning");
        }
      }
    },
    checkData() {
      if (
        this.formData.customer.country &&
        this.formData.customer.phone &&
        this.formData.customer.fullName &&
        this.formData.customer.city &&
         this.formData.totalPrice &&
        this.formData.totalPrice>0 &&
        this.formData.details.length > 0 &&
        this.formData.customer
      )
        return true;
      else return false;
    },

    removeProduct(data, index) {
      this.$confirm(this.$t('confirm_delete_product')).then(
        async (res) => {
          var index = this.formData.details.findIndex(function(o){return o.product._id === data.product._id;})
           if (index !== -1) this.formData.details.splice(index, 1);
          data.product.check = false;
          alert(this.$t('product_deleted'), "success");
        }
      );
    },
    checkPrice(data){
      if(!data.price || parseInt(data.price)==0 || typeof data.price == 'undefined') return true
      else return false;
    },
    addProductsToTemp(data,id) {
      if (data.check) {
        const detail = {
          product: data,
          unitPrice: data.price||0,
          quantity: 1,
        };
        if (data.category) {
          if (data.category._id) {
            detail.category = data.category._id;
          } else {
          }
        }

        console.log("HIII JE SUIS DETAIL", detail);
        let found = 0;
        for (let i = 0; i < this.formData.details.length; i++) {
          if (this.formData.details[i].product._id == data._id) {
            found = found + 1;
            this.numberItemRepeated = this.numberItemRepeated + 1;
          }
        }
        if (found >= 1) {
        } else {
          this.productselected.push(detail);
        }
      } else {
        let pos = this.productselected
          .map(function (e) {
            return e.product._id;
          })
          .indexOf(data._id);
        this.productselected.splice(pos, 1);
      }
    },

    addQuantity(index) {
      this.formData.details[index].quantity++;
    },
    removeQuantity(index) {
      if (this.formData.details[index].quantity <= 1) {
      } else {
        this.formData.details[index].quantity--;
      }
    },

    async OpenPopUp() {
      if(!this.formData.customer.country){
        alert(this.$t('select_country'), "warning");
        return false;
      } 
      if (this.formData.seller) {
        await this.getProducts({});
        if (this.products.length > 0) {
          this.$vm2.open("myModal");
        } else {
          if (this.currentUser.type != "Seller")
            alert(this.$t('no_products_seller'), "warning");
          else alert(this.$t('no_product_added'), "warning");
          //console.log("HIIIIII JE SUIS LA");
        }
      }
    },
    ConfirmOperation() {
      let fount = 0;
      for (let i = 0; i < this.productselected.length; i++) {
        this.formData.details.push(this.productselected[i]);
      }
      
      this.productselected = [];
      if (this.numberItemRepeated > 0) {
        alert(this.$t('product_already_added'), "success");
      }
      this.numberItemRepeated = 0;
      this.$vm2.close("myModal");
    },
    closePopUp(name) {
      //console.log("close");
       this.$vm2.close(name);
    },
    async dataToEdit() {
      if (this.$route.params.id) {
        const editData = await this.$server.get("orders", {
          id: this.$route.params.id,
        });
        this.formData = editData.content;
         if (this.formData.seller) this.isDisabled = false;
        this.selectContry=this.formData.customer.country;
        this.formData.customer.country=this.$f.getCountryNameByCode(this.options,this.formData.customer.country);
        //console.log(this.formData);
        this.formData.date =this.$moment(this.formData.date).format('yyyy-MM-DDTHH:mm');
        if (this.currentUser && this.currentUser.type && this.currentUser.type != "Seller")  await this.getSellers(this.selectContry);
        await this.getCities(this.selectContry);
        await this.getFeesShippBycity(this.formData.customer.city);
        await this.getCurrencyBycontry(this.selectContry);
       
      }
    },
    async getSellers(country) {
      const filter = {
        type: "Seller",
        limit:10,
        countries:country
      };
      const res = await this.$server.search("users", filter);
      if (res.content.results) {this.sellers = res.content.results;this.sellerExists=true;}
      else this.sellers = [];
    },
    changeSeller(seller) {
       if (this.currentUser.type != "Seller" && seller) {
        this.isDisabled = false;
        if (this.formData.details) {
          if (this.formData.details[0]) {
            if (
              this.formData.details[0].product.seller !=
              seller._id
            ) {
              this.formData.details = [];
            }
          }
        }
      } else {
        this.isDisabled = true;
      }
    },
  },

  computed: {
    resultSearchUsers() {
      if (this.searchText) {
        return this.products.filter((product) => {
          var regex = new RegExp(this.searchText, "i");

          return regex.test(product.name);
        });
      } else {
        return this.products;
      }
    },
  },

  /************************************* Pagination **************************************************/
};
</script>

<style>
.toggle__dot {
  transition: all 0.3s ease-in-out;
}
input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #68d391;
}
/* animation for vue transition tag */
dialog[open] {
  animation: appear 0.15s cubic-bezier(0, 1.8, 1, 1.8);
}
dialog::backdrop {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(54, 54, 54, 0.5));
  backdrop-filter: blur(3px);
}
@keyframes appear {
  from {
    opacity: 0;
    transform: translateX(-3rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.vs__dropdown-toggle {
    border: none;
} 
</style>
